import { Controller } from "react-hook-form";
import React from "react";
import { CustomTextField, CustomTextFieldProps } from "@cbex/ui/input";
import { SxProps, Theme } from "@mui/material";

type CustomTextfieldControllerProps = CustomTextFieldProps & {
  name: string;
  control: any;
  label?: string;
  multiLine?: boolean;
  rows?: number;
  setValue?: any;
  fullWidth?: boolean;
  controlDisabled?: boolean;
};

export const CustomTextfieldTableController = ({
  name,
  control,
  fullWidth,
  multiLine,
  controlDisabled,
  rows,
  label,
  ...props
}: CustomTextfieldControllerProps) => (
  <Controller
    name={name}
    control={control}
    render={({
      field: { onChange, value },
      fieldState: { error },
      formState,
    }) => (
      <CustomTextField
        size="small"
        error={!!error}
        onChange={onChange}
        rows={rows}
        inputProps={{
          autoComplete: "new-password",
        }}
        controldisabled={controlDisabled}
        multiline={multiLine}
        value={value}
        fullWidth={fullWidth}
        label={error ? error.message : null}
        variant="filled"
        {...props}
      />
    )}
  />
);

export const CustomTextfieldController = ({
  name,
  control,
  fullWidth,
  multiLine,
  controlDisabled,
  rows,
  label,
  ...props
}: CustomTextfieldControllerProps) => (
  <Controller
    name={name}
    control={control}
    render={({
      field: { onChange, value },
      fieldState: { error },
      formState,
    }) => (
      <CustomTextField
        helperText={error ? error.message : null}
        size="small"
        error={!!error}
        onChange={onChange}
        rows={rows}
        inputProps={{
          autoComplete: "new-password",
        }}
        controldisabled={controlDisabled}
        multiline={multiLine}
        value={value}
        fullWidth={fullWidth}
        label={label}
        variant="filled"
        {...props}
      />
    )}
  />
);
