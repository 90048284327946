import React from "react";
import { Button, Stack, Link, Box } from "@mui/material";
import { useTranslations } from "next-intl";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { ResetPasswordForm, ResetPasswordFormState } from "../index";
import { useTheme } from "@cbex/utils/theme";
import axios from "axios";

export const ResetPasswordComposite = ({
  changePasswordID,
}: {
  changePasswordID: String;
}) => {
  const t = useTranslations();
  const resetRef = React.useRef();
  const theme = useTheme();
  const [failedReset, setFailedReset] = React.useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const handleResetDetails = (val: ResetPasswordFormState) => {
    if (val && changePasswordID) {
      axios
        .post(
          "auth/resetPassword",
          { NewPassword: val.password, ChangePasswordID: changePasswordID },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res: any) => {
          setIsSubmitted(true);
          if (res && res.data && res.data.Status === 200) {
            setFailedReset(false);
            window.location.href = "/";
          } else {
            setFailedReset(true);
          }
        })
        .catch((FEerror: any) => console.log("FE error", FEerror));
    }
  };

  const handleResetClicked = () => {
    if (resetRef && resetRef.current) {
      //@ts-ignore
      resetRef.current.onSubmit();
    }
  };

  return (
    <Stack
      alignItems="center"
      height="500px"
      width="500px"
      id="ResetPasswordComposite"
    >
      <Stack mt={3} height={"20%"} mb={6}>
        <Box
          component="img"
          sx={{
            height: 80,
          }}
          //@ts-ignore
          alt="Cbex logo"
          src="https://storage.googleapis.com/cbex/logo-cbex.png"
        />
      </Stack>
      <Stack
        mt={2}
        spacing={2}
        justifyContent={"center"}
        alignItems="center"
        width="100%"
      >
        <CustomTypographyComponent variant="h5">
          {t("login.resetPassword")}
        </CustomTypographyComponent>
        <CustomTypographyComponent>
          {t("login.resetDetails")}
        </CustomTypographyComponent>
      </Stack>
      {isSubmitted && failedReset && (
        <CustomTypographyComponent
          variant="body1"
          color={theme.appColors.error}
        >
          {t("login.failedPaswordReset")}
        </CustomTypographyComponent>
      )}
      {isSubmitted && !failedReset && (
        <CustomTypographyComponent
          variant="body1"
          color={theme.palette.primary.main}
        >
          {t("login.succeededPaswordReset")}
        </CustomTypographyComponent>
      )}
      {changePasswordID === "" ||
        (changePasswordID === undefined && (
          <CustomTypographyComponent
            variant="body1"
            color={theme.appColors.error}
          >
            {t("login.noRessetToken")}
          </CustomTypographyComponent>
        ))}
      <Stack justifyContent={"center"} alignItems="center" width="100%">
        <ResetPasswordForm onResetDetails={handleResetDetails} ref={resetRef} />
      </Stack>

      <Stack>
        <Stack alignItems={"center"} width="100%">
          <Button
            color="primary"
            variant="contained"
            onClick={handleResetClicked}
            // sx={{ mr: 1, width: "60%" }}
          >
            {t("login.resetPassword")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ResetPasswordComposite;
